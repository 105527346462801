<template>
  <div>
    <common-manage ref="commonManage"></common-manage>
    <v-row>
      <v-col cols="5">
        <v-row>
          <v-col>
            <v-row no-gutters>
              <v-subheader>
                <b>
                  <h1>Details</h1>
                </b>
              </v-subheader>
            </v-row>
            <v-form ref="form" lazy-validation>
              <v-text-field v-model="newData.name" :rules="requiredRules" outlined dense>
                <template slot="label">Name<span style="color: orange"><b>*</b></span></template>
              </v-text-field>
              <v-text-field v-model="newData.phone" :rules="requiredRules" outlined dense>
                <template slot="label">Phone<span style="color: orange"><b>*</b></span></template>
              </v-text-field>
              <v-text-field v-model="newData.email" :rules="requiredRules" outlined dense>
                <template slot="label">Email</template>
              </v-text-field>
              <v-select readonly v-model="newData.enquiry_type_id" :items="EnquiryTypes" item-text="name"
                item-value="id" :rules="requiredRules" outlined dense>
                <template slot="label">Type<span style="color: orange"><b>*</b></span></template>
              </v-select>
              <v-select v-model="newData.user_id" :items="Users" item-text="name" item-value="id" :rules="requiredRules"
                outlined dense>
                <template slot="label">Assign To<span style="color: orange"><b>*</b></span></template>
              </v-select>
            </v-form>
            <v-row>
              <v-col class="py-0">
                <div class="float-right">
                  <v-btn class="mx-1" color="warning" outlined dark @click="reset()">Clear</v-btn>
                  <v-btn class="mx-1" color="success" outlined dark @click="assign()">Update</v-btn>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-row no-gutters>
              <div class="text-center">
                <v-subheader>
                  <b>
                    <h1>Deal Status</h1>
                  </b>
                </v-subheader>
              </div>
            </v-row>
            <v-row>
              <v-col class="py-0">
                <div class="text-center">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn class="mx-2" v-on="on" fab dark small color="success" large @click="actionTaken(5)">
                        <v-icon dark>
                          mdi-check
                        </v-icon>
                      </v-btn>
                    </template><span>Mark as WON</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn class="mx-2" v-on="on" fab dark small color="error" large @click="actionTaken(6)">
                        <v-icon dark>
                          mdi-close
                        </v-icon>
                      </v-btn>
                    </template><span>Mark as LOST</span>
                  </v-tooltip>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="7">
        <v-row>
          <v-col>
            <v-row no-gutters>
              <v-subheader>
                <b>
                  <v-row no-gutters>
                    <h1>Actions</h1>
                    <v-btn icon @click="addActionModel = true">
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </v-row>
                </b>
              </v-subheader>
            </v-row>
            <v-card outlined>
              <v-card-text>
                <v-data-table :headers="action_headers" :items="Actions" dense>
                  <template v-slot:[`item.index`]="{ item }">
                    {{ Actions.indexOf(item) + 1 }}
                  </template>
                  <template v-slot:[`item.remarks`]="{ item }">
                    <span v-if="item.remarks"> {{ item.remarks }} </span>
                    <span v-else> - </span>
                  </template>
                  <template v-slot:[`item.action`]="{ item }">
                    <span v-if="item.action == 0"> No Action </span>
                    <span v-else-if="item.action == 1"> Call </span>
                    <span v-else-if="item.action == 2"> Video Call </span>
                    <span v-else-if="item.action == 3"> Email </span>
                    <span v-else-if="item.action == 4"> Whatsapp </span>
                    <span v-else-if="item.action == 5"> Messaging </span>
                  </template>
                  <template v-slot:[`item.created_at`]="{ item }">
                    {{ dateFormatted(item.created_at) }}
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-row no-gutters>
              <v-subheader>
                <b>
                  <h1>History</h1>
                </b>
              </v-subheader>
            </v-row>
            <v-card outlined>
              <v-card-text>
                <v-data-table :headers="headers" :items="History" dense>
                  <template v-slot:[`item.index`]="{ item }">
                    {{ History.indexOf(item) + 1 }}
                  </template>
                  <template v-slot:[`item.created_at`]="{ item }">
                    {{ dateFormatted(item.created_at) }}
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <!--Add -->
    <v-dialog v-model="addActionModel" width="50%">
      <v-card>
        <v-card-title>
          <v-subheader><b>
              <h1>Actions Taken</h1>
            </b></v-subheader>
          <v-spacer></v-spacer>
          <v-icon color="error" @click="addActionModel = false">mdi-close</v-icon>
        </v-card-title>

        <v-divider></v-divider>
        <v-card-text class="pt-7">
          <v-form ref="form" lazy-validation>
            <v-row>
              <v-col>
                <v-radio-group v-model="actionData.action_type" row dense :rules="requiredRules">
                  <v-radio v-for="n in ActionCategory" :key="n" :label="n.name" :value="n.id"></v-radio>
                  <template slot="label">Action Category<span style="color: orange"><b>*</b></span> : </template>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <v-select v-model="actionData.action" :items="ActionTypes" item-text="name" item-value="value"
                  :rules="requiredRules" outlined dense>
                  <template slot="label">Action<span style="color: orange"><b>*</b></span></template>
                </v-select>
              </v-col>
              <v-col cols="4">
                <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                  offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="actionData.date" label="Date" dense outlined prepend-icon="mdi-calendar"
                      readonly v-bind="attrs" v-on="on" :rules="requiredRules"></v-text-field>
                  </template>
                  <v-date-picker v-model="actionData.date" @input="menu = false"></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="4">
                <v-menu ref="menu2" v-model="menu2" :close-on-content-click="false" :nudge-right="40"
                  :return-value.sync="time" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="actionData.time" dense outlined label="Time"
                      prepend-icon="mdi-clock-time-four-outline" readonly v-bind="attrs" v-on="on"
                      :rules="requiredRules"></v-text-field>
                  </template>
                  <v-time-picker v-if="menu2" v-model="actionData.time" full-width
                    @click:minute="$refs.menu2.save(actionData.time)"></v-time-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-textarea v-model="actionData.remarks" outlined dense>
                  <template slot="label">Remarks</template>
                </v-textarea>
              </v-col>
            </v-row>
          </v-form>
          <v-row>
            <v-col class="py-0">
              <div class="float-right">
                <v-btn class="mx-1" color="warning" outlined dark @click="reset()">Clear</v-btn>
                <v-btn class="mx-1" color="success" outlined dark @click="updateActions()">Add</v-btn>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";
import WebService from "@/service/WebService";
export default {
  data() {
    return {
      id: "",
      menu: '',
      menu2: '',
      EnquiryTypes: [],
      ActionCategory: [],
      addActionModel: false,
      Actions: [],
      Users: [],
      History: [],
      newData: {},
      actionData: {
        web_enquiry_id: this.$route.params.id
      },
      headers: [
        { text: "#", value: "index" },
        { text: "Assigned To", value: "assigned_to.name" },
        { text: "Assigned By", value: "assigned_by.name" },
        { text: "Assigned @", value: "created_at" },
      ],
      action_headers: [
        { text: "#", value: "index" },
        { text: "Action", value: "action" },
        { text: "Entered By", value: "user.name" },
        { text: "Remarks", value: "remarks" },
        { text: "Date", value: "created_at" },
      ],
      ActionTypes: [],
      // Rules
      whiteSpaceRules: [
        (v) => /^(?!\s)[a-zA-Z0-9_\s-]*$/.test(v) || "Enter Valid Value",
      ],
      imageRules: [
        (value) =>
          !value ||
          value.size < 2000000 ||
          "Avatar size should be less than 2 MB!",
      ],
      requiredRules: [(v) => !!v || "This field is required"],
      numberRules: [
        (v) => !!v || "This field is required.",
        (v) => /^\d{0,9}(\.\d{1,3})?$/.test(v) || "Enter Valid Amount",
      ],
    };
  },
  mounted() {
    this.id = this.$route.params.id;
    this.getUsers();
    this.getEnquiryTypes();
    this.getFiles();
    this.getActions();
    this.getActionCategories();
  },
  methods: {
    getFiles() {
      this.$refs.commonManage.open(1, true);
      WebService.getCall("web-enquiry/" + this.id).then((response) => {
        this.newData = response.data.data.enquiry;
        this.newData.user_id = response.data.data.enquiry.assigned_to;
        this.History = response.data.data.history;
        this.Actions = response.data.data.actions;
        this.$refs.commonManage.open(1, false);
      });
    },
    getEnquiryTypes() {
      this.$refs.commonManage.open(1, true);
      WebService.getCall("enquiry-types").then((response) => {
        this.EnquiryTypes = response.data.data.types;
        this.$refs.commonManage.open(1, false);
      });
    },
    getActions() {
      this.$refs.commonManage.open(1, true);
      WebService.getCall("action-list").then((response) => {
        this.ActionTypes = response.data.data.types;
        this.$refs.commonManage.open(1, false);
      });
    },
    getActionCategories() {
      this.$refs.commonManage.open(1, true);
      WebService.getCall("enquiry-action-list").then((response) => {
        this.ActionCategory = response.data.data;
        this.$refs.commonManage.open(1, false);
      });
    },
    async actionTaken(params) {
      if (await this.$refs.commonManage.assign()) {
        this.$refs.commonManage.open(1, true);
        WebService.putCall("web-enquiry/" + this.id, {
          'enquiry_action_id': params
        }).then((response) => {
          let data = {};
          data.message = response.data.message;
          data.colour = "success";
          this.$refs.commonManage.open(3, data);
          this.$router.push("/assigned");
          this.$refs.commonManage.open(1, false);
        });
      }
    },
    updateActions() {
      if (this.$refs.form.validate()) {
        this.$refs.commonManage.open(1, true);
        WebService.postCall("web-enquiry-action-taken", this.actionData).then((response) => {
          if (response.data.status == 200)
            this.getFiles()
          this.$refs.commonManage.open(1, false);
        });
      }
    },
    getUsers() {
      this.$refs.commonManage.open(1, true);
      WebService.getCall("users?assign=1").then((response) => {
        this.Users = response.data.data.data;
        this.$refs.commonManage.open(1, false);
      });
    },
    async assign() {
      if (await this.$refs.commonManage.assign()) {
        this.$refs.commonManage.open(1, true);
        WebService.putCall("web-enquiry/" + this.newData.id, {
          assigned_to: this.newData.user_id,
        }).then((response) => {
          let data = {};
          data.message = response.data.message;
          data.colour = "success";
          this.$refs.commonManage.open(3, data);
          this.$router.push("/assigned");
          this.$refs.commonManage.open(1, false);
        });
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    dateFormatted(params) {
      return params ? moment(params).format("D-M-Y h:m:s a") : "";
    },
  },
  beforeRouteEnter(to, from, next) {
    if (localStorage.getItem("loggedIn") === "true") {
      next();
    } else {
      next("/");
    }
  },
};
</script>
